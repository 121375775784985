































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator';

import ProductTile from '@/components/Products/ProductTile.vue';
import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';
import { BusyList, BusyObject } from '@/models/Busy';
import { AddressesService, Package, PackagesService, Product, ProductsService, AccountAddress, Subscription, SubscriptionsService, PayPalSubscriptionApproval, PayPalSubscription } from '@/api/braendz';
import { AccountInfo } from '@azure/msal-browser';
import PayPalSubscribeButtons from '@/components/Checkout/PayPalSubscribeButtons.vue';
import VerticalDataField from '@/components/Fields/VerticalDataField.vue'
import TermsPopup from '@/components/Popups/TermsPopup.vue';

@Component({
  components: {
    ProductTile,
    PayPalSubscribeButtons,
    VerticalDataField,
    TermsPopup
  },
  metaInfo() {
    return {
      title: this.$i18n.t("insights.title").toString(),
      meta: [
        // Search Engines:
        { name: 'title', content: this.$i18n.t("subscribe.metaTags.title").toString() },
        { name: 'description', content: this.$i18n.t("subscribe.metaTags.description").toString() },
        { name: 'keywords', content: this.$i18n.t("metaTags.keywords").toString() },
        { name: 'author', content: this.$i18n.t("metaTags.author").toString() },
        { name: 'language', content: this.$i18n.locale },
        { name: 'robots', content: [RobotOption.All].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("subscribe.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("subscribe.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Subscribe extends Vue {

  // Member:
  public step = 1;
  public termsVisible = false;

  public products = new BusyList<Product>();
  public selectedProduct: Product | null = null;

  public packages = new BusyList<Package>();
  public selectedPackages: Package[] = [];

  public billingAddress = new BusyObject<AccountAddress>({});
  public billingAddressValid = false;

  public termsAgreed = false;

  public activatedSubscription = new BusyObject<Subscription>();

  public result: 'Succeeded' | 'ErrorPayPal' | 'ErrorBraendz' | 'Cancelled' | 'UnexpectedState' | null = null;
  public payPalError: string | null = null;

  // Getter & Setter:
  public get userAccount(): AccountInfo | null {
    return this.$store.state.userAccount;
  }

  public get planId(): string | null {
    return this.selectedProduct?.payPalPlanId ?? null;
  }

  public get activeSubscription(): BusyObject<Subscription> {
      return this.$store.state.activeSubscription;
  }

  public get activePayPalSubscription(): PayPalSubscription | null | undefined {
    return this.activeSubscription.object?.payPalSubscription;
  }

  public get numberOfMissingPackages(): number | null {
    if(this.selectedProduct?.includedPackages) {
      return this.selectedProduct.includedPackages - this.selectedPackages.length;
    }
    return null;
  }

  public get isSubscriptionUpdate() {
    const payPalSubscriptionId = this.activeSubscription.object?.payPalSubscription?.id;
    const payPalSubscriptionStatus = this.activeSubscription.object?.payPalSubscription?.status;
    
    return !!payPalSubscriptionId && (payPalSubscriptionStatus === 'ACTIVE' || payPalSubscriptionId === 'SUSPENDED');
  }

  // Watcher
  @Watch('selectedProduct')
  public onSelectedProductChanged() {
    this.selectedPackages = [];
  }

  // Lifecycle Methods:
  public mounted() {
      this.refreshProducts();
      this.refreshPackages();

      if(this.userAccount) {
        this.getBillingAddress();
      }

      this.readQueryParameters();
  }

  // Stepper Methods
  public finishStepProductSelection() {
    this.step = 2;
    this.updateQueryParameters();
  }

  public async finishStepBillingAddress() {
    await this.upsertBillingAddress();
    this.step = 3;
    this.updateQueryParameters();
  }

  public async finishStepPayment() {
    this.step = 4;
    this.updateQueryParameters();
  }

  public restart() {
    this.step = 1;
    this.payPalError = null;
    this.result = null;
    this.termsAgreed = false;
    this.updateQueryParameters();
  }

  public getSubscriptionTransformation(product: Product): 'Subscribe' | 'Downgrade' | 'Same' | 'Upgrade' {
    if(this.activeSubscription.object?.state !== 'Active')  {
      return 'Subscribe';
    }
    return this.$braendz.checkSubscriptionTransformation(this.activeSubscription.object?.product, product);
  }

  // Payment State Handling:
  public onPaymentCancelled(data: any) {
    this.result = 'Cancelled';
    this.finishStepPayment();
  }

  public onPaymentFailed(error: any) {
    this.payPalError = error;
    this.result = 'ErrorPayPal';
    this.finishStepPayment();
  }

  public onActivationFailed() {
    this.result = 'ErrorBraendz';
    this.finishStepPayment();
  }

  public onSucceeded() {

    if(this.activatedSubscription.object?.state === 'Active') {
      this.result = 'Succeeded';
    }
    else {
      this.result = 'UnexpectedState';
    }
    
    this.finishStepPayment();
  }

  // Processing Methods
  public async refreshProducts() {
      await this.products.load(async () => {
        return (await ProductsService.getProducts(false)).filter(i => i.action === 'Purchase');
      });

      if(this.$route.query.product) {
        const product = this.$route.query.product as string;
        this.selectedProduct = this.products.list.find(i => i.key === product) ?? null;
      }
  }

  public async refreshPackages() {
      await this.packages.load(async () => {
        return (await PackagesService.getPackages(false));
      });

      if(this.$route.query.packages) {
        const packageKeys = this.$route.query.packages as string[];
        const selected = this.packages.list?.filter(i => packageKeys?.includes(i.key));
        if(selected) {
          this.selectedPackages.push(...selected);
        }
      }
  }

  public async refreshActivatedSubscription() {
      await this.activatedSubscription.load(async () => {
        if(this.activatedSubscription.object?.id) {
          return SubscriptionsService.getSubscription(this.activatedSubscription.object.id)
        }
        else {
          return SubscriptionsService.getActiveSubscriptionCurrentUserDefaultAccount();
        }
      });

      if(this.step === 4) {
        if(this.activatedSubscription.object?.state === 'Active') {
          this.result = 'Succeeded';
        }
        else {
          this.result = 'UnexpectedState';
        }
      }
  }

  public isPackageSelectionDisabled(pkg: Package): boolean {
    if(this.selectedPackages.includes(pkg)) {
      return false;
    }
    else if(!(this.selectedProduct?.includedPackages)) {
      return true;
    }
    else if(this.selectedProduct.includedPackages - this.selectedPackages.length <= 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public async getBillingAddress() {
    await this.billingAddress.load(async () => {
      const result = await AddressesService.getBillingAddressCurrentUserDefaultAccount();
      return result ?? {};
    });
  }

  public async upsertBillingAddress() {
    await this.billingAddress.update(async () => {
      if(this.billingAddress.object) {
        return await AddressesService.upsertBillingAddressCurrentUserDefaultAccount(this.billingAddress.object);
      }
      else {
        return null;
      }
    });
  }

  public async createSubscription(payPalSubscriptionId: string, planId: string): Promise<void> {
    try {
      await this.activatedSubscription.create(async () => {
        return await SubscriptionsService.createPayPalSubscriptionCurrentUserDefaultAccount({
          payPalSubscriptionId: payPalSubscriptionId,
          payPalPlanId: planId,
          packageKeys: this.selectedPackages.map(i => i.key) 
        });
      });

      if(this.activatedSubscription.object) {
        this.updateVuexActiveSubscription(this.activatedSubscription.object);
      }

      this.onSucceeded();
    } catch {
      this.onActivationFailed();
    }
  }

  public async updateSubscription(payPalSubscriptionId: string, planId: string): Promise<void> {
    try {
      await this.activatedSubscription.update(async () => {
        return await SubscriptionsService.updatePayPalSubscription(payPalSubscriptionId, {
          payPalPlanId: planId,
          packageKeys: this.selectedPackages.map(i => i.key) 
        });
      });

      if(this.activatedSubscription.object) {
        this.updateVuexActiveSubscription(this.activatedSubscription.object);
      }

      this.onSucceeded();
    } catch {
      this.onActivationFailed();
    }
  }

  public updateVuexActiveSubscription(activeSubscription: Subscription) {
    this.$store.commit('setActiveSubscription', activeSubscription);
  }

  // State Handling
  public updateQueryParameters() {
    this.$router.replace({ query: { step: this.step.toString(), product: this.selectedProduct?.key, packages: this.selectedPackages.map(i => i.key) } });
  }

  public readQueryParameters() {
    if(this.$route.query.step) {
      this.step = Number(this.$route.query.step);
    }
    if(this.$route.query.product) {
      this.selectedProduct = this.selectedProduct = this.products.list?.find(i => i.key === this.$route.query.product) ?? null;
    }
    if(this.$route.query.packages) {
      const packageKeys = this.$route.query.packages as string[];
      const selected = this.packages.list?.filter(i => packageKeys?.includes(i.key));
      if(selected) {
        this.selectedPackages.push(...selected);
      }
    }
  }

}
