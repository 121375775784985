








































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Product } from '@/api/braendz';

@Component({
  components: {
  }
})
export default class ProductTile extends Vue {
  
  // Member:
  @Prop({ required: true })
  public product!: Product;

  @Prop({ required: false, default: 'None', type: String})
  public action!: 'None' | 'Subscribe' | 'GetStarted';

  // Getter:

  // Lifecycle Methods:

}
