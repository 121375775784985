import { render, staticRenderFns } from "./TermsPopup.vue?vue&type=template&id=8cb77c22&scoped=true&"
import script from "./TermsPopup.vue?vue&type=script&lang=ts&"
export * from "./TermsPopup.vue?vue&type=script&lang=ts&"
import style0 from "./TermsPopup.vue?vue&type=style&index=0&id=8cb77c22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cb77c22",
  null
  
)

export default component.exports