










import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { loadScript, PayPalNamespace } from "@paypal/paypal-js";
import { GlobalEventType } from '@/models/GlobalEventType';
import { DescribedError } from '@/models/Errors';
import { Notification, NotificationSeverity } from '@/models/Notification'
import { BusyObject } from '@/models/Busy';
import { ConfigurationsService, PayPalConfigurations, PayPalSubscription } from '@/api/braendz';

export enum PaymentState {
    Initializing,
    Loaded,
    Subscribing,
    Completed,
    Cancelled,
    Failed,
    LoadingFailed
}

// Typeguard for cancellation data:
interface OnCancelData {
    orderID: string;
}

@Component({})
export default class PayPalSubscribeButtons extends Vue {
    public isBusy = false;
    public paymentState = PaymentState.Initializing;
    public payPalConfigurations: PayPalConfigurations | null = null;

    // Component Properties:
    @Prop({ required: true })
    public planId!: string;

    @Prop({ required: false })
    public payPalSubscription?: PayPalSubscription;

    @Prop({ required: false })
    public color?: "gold" | "blue" | "silver" | "white" | "black";

    @Prop({ required: false })
    public shape?: "rect" | "pill";

    @Prop({ required: false })
    public layout?: "vertical" | "horizontal";

    @Prop({ required: false })
    public label?: "paypal" | "checkout" | "buynow" | "pay" | "installment" | "subscribe" | "donate";

    @Prop({ required: false, type: Boolean, default: false })
    public disabled!: boolean;

    @Prop({ required: true })
    public createSubscription!: (id: string | null | undefined, planId: string) => Promise<void>;

    @Prop({ required: true })
    public updateSubscription!: (id: string | null | undefined, planId: string) => Promise<void>;

    // Getter
    public get isUpdate(): boolean {
        const payPalSubscriptionId = this.payPalSubscription?.id;
        const payPalSubscriptionStatus = this.payPalSubscription?.status;

        return !!payPalSubscriptionId && (payPalSubscriptionStatus === 'ACTIVE' || payPalSubscriptionId === 'SUSPENDED');
    }

    public get payPalSubscriptionId(): string | null | undefined {
        return this.payPalSubscription?.id;
    }

    // Component Lifecycle:
    public async mounted(): Promise<void> {
        this.onPaymentStateChanged(PaymentState.Initializing);
        this.isBusy = true;

        let payPal : PayPalNamespace | null = null;

        try {
            this.payPalConfigurations = await ConfigurationsService.getPayPalConfigurations();
        }
        catch(error) {
            this.isBusy = false;
            this.$emitter.emit(GlobalEventType.ErrorOccurred, { innerError: error, friendlyMessage: this.$t('payPal.errorfailedToLoadPayPalConfigurations', { error: error }) } as DescribedError);
            this.onPaymentStateChanged(PaymentState.LoadingFailed);
            return;
        }

        try {
            payPal = await loadScript({ clientId: this.payPalConfigurations.clientId ?? "", currency: "EUR", vault: true, intent: "subscription" });
        }
        catch(error) {
            this.isBusy = false;
            this.$emitter.emit(GlobalEventType.ErrorOccurred, { innerError: error, friendlyMessage: this.$t('payPal.errorfailedToLoadScript', { error: error }) } as DescribedError);
            this.onPaymentStateChanged(PaymentState.LoadingFailed);
            return;
        }

        if(!payPal) {
            this.isBusy = false;
            this.$emitter.emit(GlobalEventType.Notified, new Notification(NotificationSeverity.Error, this.$t('payPal.errorNoScriptReceived').toString()));
            this.onPaymentStateChanged(PaymentState.LoadingFailed);
            return;
        }

        if(!payPal.Buttons) {
            this.isBusy = false;
            this.onPaymentStateChanged(PaymentState.LoadingFailed);
            return;
        }

        try {
            payPal.Buttons({ 
                style: {
                    color: this.color,
                    shape: this.shape,
                    layout: this.layout,
                    label: this.label
                },
                createSubscription: (data, actions) => {
                    this.onPaymentStateChanged(PaymentState.Subscribing);

                    if(this.payPalSubscriptionId && this.isUpdate) {
                        return actions.subscription.revise(this.payPalSubscriptionId, {
                            plan_id: this.planId
                        });
                    } else {
                        return actions.subscription.create({
                            plan_id: this.planId
                        });
                    }
                },
                onApprove: (data, actions) => {
                    this.onPaymentStateChanged(PaymentState.Completed);
                    if(this.isUpdate) {
                        return this.updateSubscription(data.subscriptionID, this.planId);
                    }
                    else {
                        return this.createSubscription(data.subscriptionID, this.planId);
                    }
                },
                onCancel: async (data, actions) => {
                    this.onPaymentStateChanged(PaymentState.Cancelled);
                    this.onCancelled(data);
                },
                onError: (error) => {
                    this.onPaymentStateChanged(PaymentState.Failed);
                    this.onFailed(error);
                }
            }).render("#paypal-buttons");
        }
        catch(error) {
            this.isBusy = false;
            this.$emitter.emit(GlobalEventType.ErrorOccurred, { innerError: error, friendlyMessage: this.$t('payPal.errorRenderButtons', { error: error }) } as DescribedError);
            this.onPaymentStateChanged(PaymentState.LoadingFailed);
            return;
        }

        this.isBusy = false;
        this.onPaymentStateChanged(PaymentState.Loaded);
    }

    public onCancelled(data: any): void {
        this.$emit('cancelled', data);
    }

    public onFailed(error: any): void {
        this.$emit('failed', error);
    }

    public onPaymentStateChanged(paymentState: PaymentState): void {
        this.paymentState = paymentState;
        this.$emit('stateChanged', paymentState);
    }
}

