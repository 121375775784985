


















































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class TermsPopup extends Vue {

    // Properties
    @Prop({ required: true })
    public model!: boolean;

    // Getter & Setter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }

    // Watcher

    // Lifecycle Methods:

    // Methods:
    public close(): void {
        this.visible = false;
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }
}
